import "./img.css";
import SimpleParallax from "../../../libraries/simple-parallax/simpleParallax.js";

let Parallax = {
  init(el, displace)  {
    this.animateItem(el, displace);
  },

  setPosition() {
    if (window.pageYOffset !== undefined) {
      return window.pageYOffset;
    } else {
      return (document.documentElement || document.body.parentNode || document.body).scrollTop;
    }
  },

  animateItem(el, displace) {
    if (typeof window.orientation !== 'undefined') { return; }
    let scrollPosition = this.setPosition();
    el.style.transform = "translate3d(0px, "+(scrollPosition / displace)+"px, 0px)";
  }
};

function openVideo(trigger) {
  const target = document.querySelector(trigger.getAttribute("data-target"));
  const player = target.querySelector("iframe");

  trigger.classList.add("play");
  target.classList.add("play");

  window.setTimeout(function() {
    player.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', "*");
  }, 350);
}

function closeVideo(trigger) {
  const target = document.querySelector(trigger.getAttribute("data-target"));
  const player = trigger.querySelector("iframe");

  trigger.classList.remove("play");
  target.classList.remove("play");

  window.setTimeout(function() {
    player.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', "*");
  }, 350);
}

document.addEventListener("turbolinks:load", event => {
  const renderer = document.querySelector(".layout-renderer");

  if (renderer != undefined) {
    document.addEventListener("click", event => {
      const element = event.target;

      if (element.classList.contains("open-video")) { openVideo(element); }
      else if (element.classList.contains("close-video")) { closeVideo(element); }
    }, false);

    const parallaxedBackgrounds = document.querySelectorAll(".parallaxed-background .parallaxed");
    if (parallaxedBackgrounds.length > 0) {
      for (let parallaxedBackground of parallaxedBackgrounds) {
        let parallaxSpeed = parseFloat(parallaxedBackground.getAttribute("data-parallax-speed")) / 10;

        if (parallaxSpeed != undefined) {
          parallaxSpeed = 0.4; }

        new SimpleParallax(parallaxedBackground, { delay: parallaxSpeed });
      }

      const settedParallaxedBackgrounds = document.querySelectorAll(".simpleParallax");
      for (let settedParallaxedBackground of settedParallaxedBackgrounds) {
        settedParallaxedBackground.style.overflow = 'visible'; }
    }
  }
}, false);
