import "./collection-carousel.css";

document.addEventListener("turbolinks:load", event => {
  const collectionCarousels = document.querySelectorAll(".layout-collection-carousel");

  for (let collectionCarousel of collectionCarousels) {
    setStyle(".project-collection-card", "color", collectionCarousel.getAttribute("data-color-h"), true);
    setStyle(".project-collection-card .site-button", "color", collectionCarousel.getAttribute("data-color-button"), false);
    setStyle(".project-collection-card .site-button", "color", invertColor(collectionCarousel.getAttribute("data-color-button"), true), true);
    setStyle(".project-collection-card .site-button", "border-color", collectionCarousel.getAttribute("data-color-button"), false);
    setStyle(".project-collection-card .site-button", "border-color", collectionCarousel.getAttribute("data-color-button"), true);
    setStyle(".project-collection-card .site-button", "background-color", collectionCarousel.getAttribute("data-color-button"), true);
  }
}, false);
