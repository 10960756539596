import "./button.css";

document.addEventListener("click", event => {
  const element = event.target;

  if (element.classList.contains("site-button") && element.type === 'button') {
    const url = element.getAttribute("data-url");

    if (url != undefined || element.type === 'submit' || element.type === 'reset') {
      const message = element.getAttribute("data-message");

      if (message != undefined && message.length > 0 ? confirm(message) : true) {
        if (element.getAttribute("data-target") === '_self') { window.location.href = url; }
        else { window.open(url, '_blank'); }
      }
    }
  }
}, false);
