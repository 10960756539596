import "./button.css";


document.addEventListener("turbolinks:load", event => {
  const layoutButtons = document.querySelectorAll(".layout-button");

  for (let layoutButton of layoutButtons) {
    const siteButton = layoutButton.querySelector(".site-button");
    setStyle(`#${layoutButton.id} .site-button`, "background-color", siteButton.getAttribute("data-button-hover-bgcolor"), true);
    setStyle(`#${layoutButton.id} .site-button`, "color", siteButton.getAttribute("data-button-hover-color"), true);
  }
}, false);
