import "./projects-presentation.css";
import "../../../libraries/swiper/swiper.js";

let projectsGallery;

function setColorMode() {
  const color = document.querySelectorAll(".color-mode-source");
  const colorModeTargets = document.querySelectorAll(".color-mode-target");

  for (let i = 0; i < colorModeTargets.length; i += 1) {
    colorModeTargets[i].style.color = color[projectsGallery.activeIndex].style.color;
  }
}

document.addEventListener("turbolinks:load", event => {
  const projectsPresentation = document.querySelector(".projects-presentation");

  if (projectsPresentation != undefined) {
    for (let swiperGallery of projectsPresentation.querySelectorAll(".swiper-container")) {
      const gallery = new Swiper(`#${swiperGallery.id}`, {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        noSwiping: true,
        speed: 1000,
        centeredSlides: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          type: "fraction"
        },
        observer: true,
        observeParents: true,
      });

      if (swiperGallery.classList.contains("gallery-hovers")) {
        projectsGallery = gallery;

        swiperGallery.addEventListener("mouseenter", event => {
          const elem = event.target.parentNode.parentNode.parentNode.querySelector(".swiper-slide-active .heading");
          if (elem != undefined) { elem.classList.add("hover"); }
        });

        swiperGallery.addEventListener("mouseleave", event => {
          const elem = event.target.parentNode.parentNode.parentNode.querySelector(".swiper-slide-active .heading");
          if (elem != undefined) { elem.classList.remove("hover"); }
        });

      } else if (swiperGallery.classList.contains("gallery-projects")) {
        gallery.on("slideChange", setColorMode, false);
      }
    }

    const fadeLayer = projectsPresentation.querySelector(".fade-layer");
    document.addEventListener("scroll", throttle(function() { fadeEffect(fadeLayer, 1200, 1); }, 10), false);

    parallax(projectsPresentation.querySelector(".layout-parallax"), 10);

    if (projectsGallery != undefined) { setColorMode(); }
  }
}, false);
