import "../stylesheets/normalize.css";
import "../assets/icons/icons.css";
import "./global.css";

import "../libraries/modernizr-custom.js";

import "../components/shared/page/page";
import "../components/shared/button/button";
import "../components/shared/contact-form/contact-form";
import "../components/shared/project-collection-card/project-collection-card";
import "../components/shared/post-collection-card/post-collection-card";
import "../components/shared/projects-carousel/projects-carousel";
import "../components/layout/renderer/renderer";
import "../components/layout/project-desc/project-desc";
import "../components/layout/project-next/project-next";
import "../components/layout/cover/cover";
import "../components/layout/img/img";
import "../components/layout/txt/txt";
import "../components/layout/video/video";
import "../components/layout/columns/columns";
import "../components/layout/img-txt/img-txt";
import "../components/layout/gallery/gallery";
import "../components/layout/button/button";
import "../components/layout/anchor/anchor";
import "../components/layout/contact-form/contact-form";
import "../components/layout/collection-carousel/collection-carousel";

import "../stylesheets/error-404.css";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");


let lastScrollTop;
let scrollDirection;

/*
  Sets a timeout for a devise notification to close it.

  params:
    timeout (integer) -> timeout in ms (default 8s).
*/
function flashMessageTimeout(timeout = 8000) {
  window.clearTimeout();

  window.setTimeout(function() {
    const notification = document.querySelector(".devise-notification");

    if (notification != undefined) {
      notification.remove();
      unlockElements();
    }
  }, timeout);
}

/*
  Search for every locaked-while-loading element and unlock it.

  For example, elements of the interface can be locked when
  a user clicks on a button that performs an action that is not instant.
*/
function unlockElements() {
  const lockedElements = document.querySelectorAll(".locked-while-loading");
  for (let lockedEelement of lockedElements) {
    lockedEelement.classList.remove("locked-while-loading"); }
}

/*
  Smooth scrolling to an anchor.
*/
function scrollAnchors(e, respond = null) {
	const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);

  e.preventDefault();

  const targetID = (respond) ? respond.getAttribute('href') : this.getAttribute('href');
  const targetAnchor = document.querySelector(targetID);

  if (!targetAnchor) {
    return; }

  const originalTop = distanceToTop(targetAnchor);

  window.scrollBy({
    top: originalTop, left: 0, behavior: 'smooth'});

  const checkIfDone = setInterval(function() {
		const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
		if (distanceToTop(targetAnchor) === 0 || atBottom) {
			targetAnchor.tabIndex = '-1';
			targetAnchor.focus();
			window.history.pushState('', '', targetID);
			clearInterval(checkIfDone);
		}
	}, 100);
}

// Shared functions <>

window.generateId = function() {
  return new Date().getTime(); };

window.sleep = function(milliseconds) {
  const start = new Date().getTime();
  for (let i = 0; i < 1e7; i += 1) {
    if (new Date().getTime() - start > milliseconds) {
      break; }
  }
};

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) return c / 2 * t * t + b;
  t--;
  return -c / 2 * (t * (t - 2) - 1) + b;
};

window.cstmScrollTo = function(element, to = 0, duration = 1000, onlyDown = false) {
  const start = element.scrollTop;

  if(onlyDown && (start > to)){
    return; }

  const change = to - start;
  const increment = 20;
  let currentTime = 0;

  const animateScroll = (() => {
    currentTime += increment;
    const val = Math.easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;

    if (currentTime < duration) {
      setTimeout(animateScroll, increment); }
  });

  animateScroll();
};

window.scrollDirection = function() {
  return scrollDirection; };

window.getSiblings = function(elem) {
  const siblings = [];
  let sibling = elem.parentNode.firstChild;

  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
      siblings.push(sibling); }

    sibling = sibling.nextSibling;
  }

  return siblings;
};

window.isInViewport = function(elem) {
  const width = elem.offsetWidth;
  const height = elem.offsetHeight;
  let top = elem.offsetTop;
  let left = elem.offsetLeft;

  while (elem.offsetParent) {
    elem = elem.offsetParent;
    top += elem.offsetTop;
    left += elem.offsetLeft;
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  );
};

window.throttle = function(func, wait, options) {
  let context, args, result;
  let timeout = null;
  let previous = 0;

  if (!options) {
    options = {}; }

  let later = function() {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);

    if (!timeout) {
      context = args = null; }
  };

  return function() {
    let now = Date.now();
    if (!previous && options.leading === false) previous = now;
    let remaining = wait - (now - previous);
    context = this;
    args = arguments;

    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      previous = now;
      result = func.apply(context, args);

      if (!timeout) {
        context = args = null; }

    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining); }

    return result;
  };
};

window.parallax = function(element, speed = 4) {
  if (
    Object.prototype.toString.call(element) != "[object NodeList]" &&
    Object.prototype.toString.call(element) != "[object Array]"
  ) {
    window.addEventListener("scroll", function() {
      if (isInViewport(element)) {
        const top = window.pageYOffset;
        element.style.transform = `translateY(-${top / speed}px)`;
      }
    });
  } else {
    window.addEventListener("scroll", function() {
      if (isInViewport(element)) {
        for (let i = 0; i < element.length; i++) {
          const top = window.pageYOffset;

          if (top >= element[i].getBoundingClientRect().top + document.body.scrollTop) {
            element[i].style.transform = `translateY(-${(top - element[i].getBoundingClientRect().top + document.body.scrollTop) / speed}px)`;
          } else if (top >= element[i].getBoundingClientRect().bottom - document.body.scrollTop) {
            window.addEventListener("scroll", function() {
              return; });
          }
        }
      }
    });
  }
};

window.macyColumns = function(defCols = 4) {
  const screenWidth = document.documentElement.clientWidth;

  if (screenWidth <= 575)       {
    return 1; }
  else if (screenWidth <= 940)  {
    return 2; }
  else if (screenWidth <= 1199) {
    return 3; }
  else                          {
    return defCols; }
};

window.createCookie = function(name, value, days) {
  let expires = "";

  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  }

  document.cookie = `${name}=${value}${expires}; path=/`;
};

window.readCookie = function(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];

    while (c.charAt(0) == " ") {
      c = c.substring(1, c.length); }

    if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length, c.length); }
  }

  return undefined;
};

window.eraseCookie = function(name) {
  createCookie(name, "", -1); };

window.runResponsiveBgs = function() {
  const responsiveBgs = document.querySelectorAll(".responsive-bg");

  if (responsiveBgs != undefined) {
    let vertical = true;

    if (window.screen.orientation != undefined) {
      vertical = ["portrait-primary", "portrait-secondary"].includes(window.screen.orientation.type); }

    const mobileScreen = document.body.clientWidth <= 1024 && vertical;

    for (const responsiveBg of responsiveBgs) {
      if (responsiveBg.style.backgroundImage != undefined) {
        let image;

        if (mobileScreen) {
          image = responsiveBg.getAttribute("data-image-vertical"); }

        if (image == undefined) {
          image = responsiveBg.getAttribute("data-image"); }

        responsiveBg.style.backgroundImage = `url(${image})`;
      }
    }
  }
};

// window.runResponsiveVideos = function() {
//   const layoutVideos = document.querySelectorAll(".layout-video iframe");

//   if (layoutVideos != undefined) {
//     const screenWidth = window.innerWidth || documentd.documentElemente.clientWidth || document.getElementsByTagName('body')[0].clientWidth;

//     for (const responsiveVideo of layoutVideos) {
//       let width = 60;

//       if (screenWidth > 1024) {
//         width = parseFloat(responsiveVideo.getAttribute("data-desktop-size")) || 60;
//       } else if (screenWidth >= 768 && screenWidth <= 1024) {
//         width = parseFloat(responsiveVideo.getAttribute("data-tablet-size")) || 100;
//       } else {
//         width = parseFloat(responsiveVideo.getAttribute("data-mobile-size")) || 100;
//       }

//       console.log(width);

//       responsiveVideo.style.width = `${width}vw`;
//       responsiveVideo.style.height = `${width / 1.7778}vw`;
//     }
//   }
// }

window.fadeEffect = function(target, ratio = 250, direction = 1) {
  let opacity = (window.pageYOffset || document.documentElement.scrollTop) / ratio;
  target.style.opacity = direction === -1 ? 1 - opacity : opacity;
};

window.flashMessage = function(type, message, timeout) {
  document.querySelector("body").insertAdjacentHTML("afterbegin", `<div class="devise-notification"><div class="devise-notification--message ${type}">${message}</div></div>`);

  flashMessageTimeout(timeout);
};

window.invertColor = function(hex, bw) {
  if (hex.indexOf('#') === 0) { hex = hex.slice(1); }

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]; }

  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.'); }

  var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);

  if (bw) {
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#000000' : '#FFFFFF'; }

  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);

  return "#" + padZero(r) + padZero(g) + padZero(b);
};

// Shared functions </>

// Listeners <>

function rootClickListener(event) {
  const element = event.target;

  if (element.classList.contains("toggle-buddy")) {
    const buddy = element.getAttribute("data-target");
    element.classList.toggle("active");

    if (element.getAttribute("data-target").includes("#")) {
      document.querySelector(buddy).classList.toggle("active"); }
    else {
      element.parentNode.parentNode.querySelector(buddy).classList.toggle("active"); }
  }

  if (element.classList.contains("lock-all")) {
    document.querySelector("body").classList.add("locked-while-loading");

    window.setTimeout(function() {
      document.querySelector("body").classList.remove("locked-while-loading");
    }, 4000);
  }
}

function rootResizeListener(event) {
  runResponsiveBgs();
  // runResponsiveVideos();
}

function rootScrollListener(event) {
  const st = window.pageYOffset || document.documentElement.scrollTop;
  scrollDirection = st > lastScrollTop ? 1 : -1;
  lastScrollTop = st <= 0 ? 0 : st;
}

window.addEventListener("resize", throttle(rootResizeListener, 20));

document.addEventListener("click", rootClickListener);

document.addEventListener("scroll", rootScrollListener);

// Listeners </>

document.addEventListener("turbolinks:load", event => {
  // runResponsiveVideos();
  runResponsiveBgs();

  // webp support checking
  Modernizr.on('webp', function(result) {
    if (result) {
      const webpElements = document.querySelectorAll(".can-i-use-webp");
      for (let webpElement of webpElements) {
        webpElement.classList.add("webp"); }

    } else {
      console.log("Este navegador no soporta el formato de imagen webp"); }
  });

  // bug fix: turbolinks and anchors
  document.addEventListener('turbolinks:click', function(event) {
    if (event.target.getAttribute('href').charAt(0) === '#') {
      return event.preventDefault(); }
  });

  // bug fix: Firefox back-button issue
  window.addEventListener("unload", event => {
    window.removeEventListener("unload", this); });

  // smooth anchor scrolling
  let links = document.getElementsByTagName("a");
	for (var i = 0; i < links.length; i++) {
		const link = links[i];

		if ((link.href && link.href.indexOf("#") != -1) && ((link.pathname == location.pathname) || ('/' + link.pathname == location.pathname)) && (link.search == location.search)) {
			link.onclick = scrollAnchors; }
	}

  window.dispatchEvent(new Event("resize"));
  rootResizeListener();

  flashMessageTimeout(); // performs devise notifications timeouts

}, false);
