import "./project-next.css";

document.addEventListener("turbolinks:load", event => {
  const projectNext = document.querySelector(".project-next a");

  if (projectNext != undefined) {
    projectNext.addEventListener("click", event => {
      event.preventDefault();

      document.querySelector("html").style.scrollBehavior = "smooth";

      document.querySelector(".layout-renderer").classList.add("fade");

      projectNext.parentNode.classList.add("fade");

      window.setTimeout(function() {
        window.scrollTo(0, document.body.scrollHeight); }, 800);

      window.setTimeout(function() {
        document.querySelector("html").style.scrollBehavior = "initial";
        window.location.href = event.target.href;
      }, 860);
    }, false);
  }
}, false);
