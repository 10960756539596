import "./page.css";

document.addEventListener("click", function(event) {
  if (event.target.classList.contains("tpt")) {
    event.preventDefault();

    cstmScrollTo(document.body, 0, 0);

    document.querySelector(".page").classList.add("bye");

    const pagesNavigationObject = document.querySelector("#pages_navigation");
    if (pagesNavigationObject != undefined) {
      pagesNavigationObject.classList.add("bye"); }

    setTimeout(function() {      
      localStorage.setItem("page_transition_on", "true");
      window.location.href = event.target.getAttribute("href") || event.target.getAttribute("data-href");
    }, 700);
  }
}, true);

document.addEventListener("turbolinks:load", function(event) {
  const page = document.querySelector(".page");

  if (page != undefined) {
    const timeout = localStorage.getItem("page_transition_on") === "true" ? 600 : 0;
    localStorage.setItem("page_transition_on", false);

    setTimeout(function() {
      page.classList.remove("welcome"); 
    }, timeout);

    const pagesNavigation = document.querySelector("#pages_navigation");
    if (pagesNavigation != undefined) {
      setTimeout(function() {
        pagesNavigation.classList.remove("welcome"); }, 600);
    }
  }
}, false);
