import "./global.js";
import "./application.css";

import "../components/shared/modal/modal";
import "../components/pages/cookies-modal/cookies-modal";
import "../components/pages/navbar/navbar";
import "../components/pages/projects-presentation/projects-presentation";
import "../components/pages/projects-grid/projects-grid";
import "../components/pages/posts-grid/posts-grid";
import "../components/pages/footer/footer";
// import "../components/pages/lang-toast/lang-toast";

import "../stylesheets/pages-index.css";
import "../stylesheets/pages-about-us.css";
import "../stylesheets/pages-contact.css";
import "../stylesheets/posts-index.css";
import "../stylesheets/work-areas-show.css";
import "../stylesheets/work-areas-service.css";

document.addEventListener("turbolinks:load", event => {
  const pageId = document.querySelector(".page").id;

  if (pageId === 'pages_about_us') {
    parallax(document.querySelector(".layout-parallax"), 7); }
}, false);
