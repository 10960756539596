import "./projects-grid.css";
import Macy from "../../../libraries/macy/macy.js";

document.addEventListener("turbolinks:load", event => {
  const projectsGalleries = document.querySelectorAll(".projects-grid");

  if (projectsGalleries != undefined) {
    for (const projectsGallery of projectsGalleries) {
      const defCols = projectsGallery.getAttribute("data-def-cols");

      let macy = Macy({
        container: `#${projectsGallery.querySelector(".masonry-container").id}`,
        margin: 24,
        columns: macyColumns(defCols)
      });
    }
  }
}, false);
