import "./cookies-modal.css";

document.addEventListener("turbolinks:load", event => {
  const cookiesModal = document.querySelector("#cookies_modal");

  if (cookiesModal != undefined) {
    if (readCookie("cookies_accepted") === undefined) {
      cookiesModal.classList.add("active");

      cookiesModal.querySelector(".close").addEventListener("click", event => {
        createCookie("cookies_accepted", "displayed", 365);
      }, false);
    } else {
      cookiesModal.remove();
    }
  }
}, false);
