import "./modal.css";

document.addEventListener("click", function(event) {
  const element = event.target;

  if (element.classList.contains("modal-trigger")) {
    try {
      const modalRef = element.getAttribute("data-modal");

      let modal;
      if (modalRef != undefined) {
        modal = document.querySelector(`#${modalRef}`); }
      else {
        modal = element.parentNode.parentNode.parentNode.parentNode.parentNode; }

      if (element.classList.contains("open")) {
        modal.classList.add("active"); }
      else if (element.classList.contains("close")) {
        modal.classList.remove("active"); }

    } catch (e) {
      console.error(`El modal que se está tratanto de mostrar no existe o no se encuentra disponible => ${e}`); }
  }
}, false);
