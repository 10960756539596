import "./video.css";

window.runResponsiveVideo = function(player) {
  const screenWidth = window.innerWidth || documentd.documentElemente.clientWidth || document.getElementsByTagName('body')[0].clientWidth;

  let width = 60;

  if (screenWidth > 1024) {
    width = parseFloat(player.getAttribute("data-desktop-size")) || 60;
  } else if (screenWidth >= 768 && screenWidth <= 1024) {
    width = parseFloat(player.getAttribute("data-tablet-size")) || 100;
  } else {
    width = parseFloat(player.getAttribute("data-mobile-size")) || 100;
  }

  player.style.width = `${width}vw`;
  player.style.height = `${width / 1.7778}vw`;
}

document.addEventListener("turbolinks:load", event => {
  const responsiveVideos = document.querySelectorAll(".layout-video iframe");
  for (const responsiveVideo of responsiveVideos) {
    runResponsiveVideo(responsiveVideo);

    document.addEventListener("scroll", event => {
      if (responsiveVideo.classList.contains("play-when-visible") && isInViewport(responsiveVideo)) {
        responsiveVideo.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', "*");
      }
    });

    window.addEventListener("resize", event => {
      runResponsiveVideo(responsiveVideo);
    });
  }
});
