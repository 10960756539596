import "./contact-form.css";

const OK_MESSAGE = {"es": "Correcto", "en": "Correct", "ca": "Correcte"};

document.addEventListener("ajax:beforeSend", event => {
  const form = event.target;

  if (form.classList.contains("contact-form")) {
    form.classList.add("loading"); }
});

document.addEventListener("ajax:success", event => {
  const form = event.target;

  if (form.classList.contains("contact-form")) {
    const [data, status, xhr] = event.detail;

    const resultFlds = form.querySelectorAll(".field--result");
    for (let resultFld of resultFlds) {
      resultFld.classList.remove("-visible"); }

    form.classList.remove("ko", "loading");
    form.classList.add("ok");
    form.reset();
  }
});

document.addEventListener("ajax:error", event => {
  const form = event.target;

  if (form.classList.contains("contact-form")) {
    const [data, status, xhr] = event.detail;
    const lang = document.documentElement.lang;

    const resultFlds = form.querySelectorAll(".field--result");
    for (let resultFld of resultFlds) {
      resultFld.innerHTML = `<p><span style="font-size: 20px; margin-right: 1rem;">✓</span>${OK_MESSAGE[lang]}</p>`;
      resultFld.classList.remove("ko", "loading");
      resultFld.classList.add("-visible", "ok");
    }

    try {
      const errors = JSON.parse(xhr.responseText).errors;
      for (let error in errors) {
        const resultFld = form.querySelector(`.${error.replace(new RegExp("_", "g"), "-")}-field .field--result`);
        resultFld.innerHTML = `<p>${errors[error]}</p>`;
        resultFld.classList.remove("ok");
        resultFld.classList.add("-visible", "ko");
      }
    } catch(e) {
      console.log(e); }

    form.classList.remove("ok");
    form.classList.add("ko");
  }
});
