import "./renderer.css";

let renderer;

window.setStyle = function(target, property, value, hover = false, important = true) {
  let style;
  if (hover) {
    const css = `${target}:hover { ${property}: ${value} ${important ? "!important" : ""}; }`;
    style = document.createElement("style");

    if (style.styleSheet) { style.styleSheet.cssText = css; }
    else { style.appendChild(document.createTextNode(css)); }
  }

  const doms = document.querySelectorAll(target);
  if (target != undefined && value != "") {
    for (const dom of doms) {
      if (hover) { dom.appendChild(style); }
      else if (dom.style[property] == "") { dom.style[property] = value; }
    }
  }
};

function getFontSize(fontSizeData) {
  const windowWidth = document.body.clientWidth;

  let fontSize = "";

  if (fontSizeData != undefined) {
    fontSizeData = fontSizeData.split(",");

    let fontSizeXXS, fontSizeXS, fontSizeDef = fontSizeData[0];

    if (fontSizeData.length >= 2) {
      fontSizeXS = fontSizeData[1];

      if (fontSizeData.length == 3) {
        fontSizeXXS = fontSizeData[2]; }
      else {
        fontSizeXXS = fontSizeXS; }
    } else {
      fontSizeXS = fontSizeDef;
      fontSizeXXS = fontSizeDef;
    }

    if (windowWidth > 1024) {
      fontSize = fontSizeDef; }
    else if (windowWidth > 575) {
      fontSize = fontSizeXS; }
    else {
      fontSize = fontSizeXXS; }
  }

  return fontSize;
}

function runAnimationEvents() {
  const doms = document.getElementsByClassName("layout-component");
  for (const dom of doms) { if (isInViewport(dom)) { dom.classList.add("animate"); } }
}

function setFonts() {
  setStyle(".heading", "font-weight", renderer.getAttribute("data-heading-weight"));
  setStyle(".heading", "font-style", renderer.getAttribute("data-heading-style"));
  // setStyle(".heading", "font-size", getFontSize(renderer.getAttribute("data-heading-size")), false, true);
  for (let target of document.querySelectorAll(".heading")) {
    target.classList.add(renderer.getAttribute("data-heading-font"));

    let fontSize = getFontSize(target.getAttribute("data-font-size"));
    if (fontSize != undefined && fontSize != "") {
      target.style.fontSize = fontSize; }
    else {
      target.style.fontSize = getFontSize(renderer.getAttribute("data-heading-size")); }
  }

  setStyle(".subheading", "font-weight", renderer.getAttribute("data-subheading-weight"));
  setStyle(".subheading", "font-style", renderer.getAttribute("data-subheading-style"));
  // setStyle(".subheading", "font-size", getFontSize(renderer.getAttribute("data-subheading-size")), false, true);
  for (let target of document.querySelectorAll(".subheading")) {
    target.classList.add(renderer.getAttribute("data-subheading-font"));

    let fontSize = getFontSize(target.getAttribute("data-font-size"));
    if (fontSize != undefined && fontSize != "") {
      target.style.fontSize = fontSize; }
    else {
      target.style.fontSize = getFontSize(renderer.getAttribute("data-subheading-size")); }
  }

  setStyle(".layout-renderer", "font-size", renderer.getAttribute("data-text-size"));
  // setStyle(".text", "font-size", getFontSize(renderer.getAttribute("data-text-size")), false, true);
  for (let target of document.querySelectorAll(".text")) {
    target.classList.add(renderer.getAttribute("data-text-font"));

    let fontSize = getFontSize(target.getAttribute("data-font-size"));
    if (fontSize != undefined && fontSize != "") {
      target.style.fontSize = fontSize; }
    else {
      target.style.fontSize = getFontSize(renderer.getAttribute("data-text-size")); }
  }
}

document.addEventListener("turbolinks:load", event => {
  renderer = document.querySelector(".layout-renderer");

  if (renderer != undefined) {
    // colors <>
    setStyle("body", "background-color", renderer.getAttribute("data-bgcolor"));
    setStyle(".heading", "color", renderer.getAttribute("data-heading-color"));
    setStyle(".subheading", "color", renderer.getAttribute("data-subheading-color"));
    setStyle(".highlight", "color", renderer.getAttribute("data-highlight-color"));
    setStyle(".link", "color", renderer.getAttribute("data-a-color"));
    setStyle(".link", "color", renderer.getAttribute("data-a-color-h"), true);
    setStyle(".site-button", "background-color", renderer.getAttribute("data-button-bgcolor"));
    setStyle(".site-button", "background-color", renderer.getAttribute("data-button-bgcolor-h"), true);
    setStyle(".site-button", "color", renderer.getAttribute("data-button-color"));
    setStyle(".site-button", "color", renderer.getAttribute("data-button-color-h"), true);

    const navbar = document.querySelector(".navbar");
    if (navbar != undefined && document.body.clientWidth > 1024) {
      navbar.classList.add("project-customization");
      setStyle(".navbar--logo svg", "fill", renderer.getAttribute("data-nav-logo-color"));
      setStyle(".navbar--container", "background-color", renderer.getAttribute("data-nav-bgcolor"));
      setStyle(".navbar .navbar--menu--item a", "color", renderer.getAttribute("data-nav-color"));
      setStyle(".navbar .navbar--menu--item", "color", renderer.getAttribute("data-nav-color"));
      setStyle(".navbar .navbar--menu--item a", "color", renderer.getAttribute("data-nav-color-h"), true);
    }

    document.querySelector("main").style.background = renderer.getAttribute("data-background-color");
    // colors </>

    // fuentes
    setFonts();

    // other settings <>
    setStyle(".project-next", "color", renderer.getAttribute("data-color"));
    setStyle(".project-next", "border-color", renderer.getAttribute("data-color"));
    setStyle(".project-next", "background-color", renderer.getAttribute("data-background-color"));

    let show_navigation_bar = renderer.getAttribute("data-show-navigation-bar") || "Sí";
    if (show_navigation_bar === "No") {
      setStyle(".navbar", "display", "none"); }

    // other settings </>

    // listeners <>
    document.addEventListener("scroll", throttle(runAnimationEvents, 10), false);
    runAnimationEvents();

    window.addEventListener("resize", throttle(setFonts, 50), false);
    window.dispatchEvent(new Event("resize"));
    // listeners </>
  }
}, false);
