import "./navbar.css";

let navbar;
let lastScrollTop = 0;

function navbarStyle() {
  if (navbar != undefined) {
    const scrollToTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollToTop <= 2 && navbar.classList.contains("-scrolled")) {
      navbar.classList.remove("-scrolled", "-hide"); }
    else if (scrollToTop > 0) {
      if (!navbar.classList.contains("-scrolled")) {
        navbar.classList.add("-scrolled"); }

      if (scrollToTop > lastScrollTop) {
        navbar.classList.add("-hide"); }
      else {
        navbar.classList.remove("-hide"); }
    }

    lastScrollTop = scrollToTop <= 0 ? 0 : scrollToTop;
  }
}

document.addEventListener("turbolinks:load", function(event) {
  navbar = document.querySelector(".navbar") || [];

  if (navbar != undefined) {
    document.addEventListener("scroll", throttle(navbarStyle, 50));

    navbar.addEventListener("click", event => {
      const element = event.target;

      if (element.classList.contains("navbar--button")) {
        navbar.classList.toggle("open"); }
      else if (element.classList.contains("link")) {
        navbar.classList.remove("open"); }
    });

    navbarStyle();
  }
}, false);
