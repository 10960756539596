import "./cover.css";

document.addEventListener("turbolinks:load", event => {
  const layoutCover = document.querySelector(".layout-cover");
  if (layoutCover != undefined) {
    const layoutCoverScrollSignal = layoutCover.querySelector(".layout-cover--scroll-signal");
    if (layoutCoverScrollSignal != undefined) {
      document.addEventListener("scroll", throttle(function() {
        fadeEffect(layoutCoverScrollSignal, 350, -1);
      }, 10), false);
    }

    const logo = layoutCover.querySelector(".parallaxed-logo");
    if (logo != undefined) {
      parallax(logo, 5);
    }
  }
});
