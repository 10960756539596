import "./projects-carousel.css";
import "../../../libraries/swiper/swiper.js";

document.addEventListener("turbolinks:load", event => {
  const projectsCarousel = document.querySelectorAll(".projects-carousel--swiper-container");

  if (projectsCarousel != undefined) {
    for (let projectCarousel of projectsCarousel) {
      if (projectCarousel.querySelectorAll(".swiper-slide").length > 0) {
        let slidesPerView = projectCarousel.getAttribute("data-slides-per-view");

        const wwidth = document.body.clientWidth;

        if (slidesPerView > 1) {
          if (wwidth > 575 && wwidth <= 1024) { slidesPerView = 1.6; }
          else if (wwidth <= 575) { slidesPerView = 1.4; }
        }

        new Swiper(`#${projectCarousel.id}`, {
          slidesPerView: slidesPerView,
          spaceBetween: 20,
          slidesOffsetBefore: projectCarousel.parentNode.classList.contains("avoid-offset-before") ? 0 : 20,
          slidesOffsetAfter: projectCarousel.parentNode.classList.contains("avoid-offset-after") ? 0 : 20,
          observer: true,
          observeParents: true,
          roundLengths: true
        });
      }
    }
  }
}, false);
