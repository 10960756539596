import "./gallery.css";
import Macy from "../../../libraries/macy/macy.js";
import "../../../libraries/swiper/swiper.js";

document.addEventListener("turbolinks:load", event => {
  const layoutGalleries = document.querySelectorAll(".layout-gallery");

  if (layoutGalleries != undefined) {
    for (const layoutGallery of layoutGalleries)  {
      const swiperGallery = layoutGallery.querySelector(".layout-gallery--swiper-container");

      if (swiperGallery != undefined) {
        const fade = swiperGallery.classList.contains("fade");
        let notAsBg = swiperGallery.querySelector(".swiper-slide");

        if (notAsBg != undefined) { notAsBg = notAsBg.classList.contains("media-not-background"); }
        else { notAsBg = false; }

        const autoplayDelay = layoutGallery.getAttribute("data-autoplay-delay");

        const gallery = new Swiper(`#${swiperGallery.id}`, {
          slidesPerView: notAsBg ? (window.innerWidth > 1024 ? 1.5 : 1.1) : 1,
          loop: false,
          centeredSlides: notAsBg ? true : false,
          fadeEffect: {
            crossFade: true
          },
          effect: fade ? "fade" : "swipe",
          autoplay: {
            delay: parseInt(autoplayDelay, 10) * 1000,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          observer: true,
          observeParents: true,
        });

        if (autoplayDelay == undefined || autoplayDelay == 0 || autoplayDelay == '0') { gallery.autoplay.stop(); }

      } else {
        const masonryGallery = layoutGallery.querySelector(".masonry-container");

        if (masonryGallery != undefined) {
          let macy = Macy({
            container: `#${masonryGallery.id}`,
            margin: 12,
            columns: macyColumns()
          });
        }
      }
    }
  }
}, false);
