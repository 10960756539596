import "./columns.css";

function updateLayoutColumnWidth() {
  const layoutColumns = document.querySelectorAll(".layout-columns--col");

  if (layoutColumns != undefined) {
    for (let layoutColumn of layoutColumns) {
      const windowWidth = document.body.clientWidth;
      let columnWidth = 0;

      if (windowWidth > 1024) { columnWidth = layoutColumn.getAttribute("data-size-def"); }
      else if (windowWidth >= 575 && windowWidth <= 1024) { columnWidth = layoutColumn.getAttribute("data-size-xs"); }
      else if (windowWidth < 575) { columnWidth = layoutColumn.getAttribute("data-size-xxs"); }

      if (columnWidth > 0) {
        layoutColumn.style.width = `${columnWidth}%`;
        layoutColumn.classList.remove("hide");
      }
      else { layoutColumn.classList.add("hide"); }
    }
  }
}

document.addEventListener("turbolinks:load", event => {
  const layoutColumns = document.querySelectorAll(".layout-columns");

  if (layoutColumns != undefined) {
    updateLayoutColumnWidth();
    window.addEventListener("resize", throttle(updateLayoutColumnWidth, 20));
  }
}, false);
